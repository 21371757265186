<template>
  <div>
    <div class="box">
      <el-card class="left">
        <ul style="overflow:auto">
          <li class="search_type_box" @click="toggleClass(-1, null)" :class="{ selected: cur == -1 }"><span class="fs_01">全部</span>  <el-input
                    placeholder="请输入分类名"
                    v-model="search_type"
                    @input='handleSelect'
                    class="inline-input"
                    size="mini"
                  ></el-input></li>
          <li
            v-for="(item, index) in materialList"
            :key="item.id"
            @click="toggleClass(index, item.id)"
            :class="{ selected: cur == index }"
          >
            {{ item.title }}
            <div class="ck">
              <i class="el-icon-edit" @click="editTab(item)"></i>
              <i class="el-icon-delete" @click="removeTab(item.id)"></i>
            </div>
          </li>
        </ul>
        <el-button
          type="primary"
          size="medium"
          class="add"
          @click="addDialog = true"
          >添加分类</el-button
        >
      </el-card>
      <div class="right">
        <el-card class="navs">
          <div class="headers">
            <ul>
              <li
                :class="{ selected: tabs == index }"
                @click="toggletabs(index, item.file_type)"
                v-for="(item, index) in tab"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="search">
            <div class="search-left">
              <el-form :inline="true" label-width="80px" ref="search">
                <el-form-item label="素材名称：" label-width="100px">
                  <el-input
                    placeholder="请输入素材名"
                    v-model="queryparms.file_name"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="toQuery">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="search-right">
              <el-button type="primary" @click="toBatchUpload">批量上传</el-button>
              <el-button type="primary" @click="toUploadMaterial">上传素材</el-button>
              <el-button type="primary" @click="recycleBin"
                >回收站</el-button
              >
            </div>
          </div>
        </el-card>

        <el-card class="contents">
          <div class="material" v-for="(item, index) in FileList" :key="index" >
            <img
              src="../../assets/img/mp3.jpg"
              alt=""
              v-if="item.file_type === 'audio'"
              @click="exhibitionSay(item)"
            />

            <img :src="item.avthumb" alt="" v-else   @click="exhibitionSay(item)"/>
            <p style="white-space: nowrap;text-overflow: ellipsis;">{{ item.file_name }}</p>
            <div class="types">
              <div class="types-left">
                <img
                  src="../../assets/img/material.png"
                  alt=""
                  v-if="item.file_type === 'image'"
                />
                <img
                  src="../../assets/img/图层 5.png"
                  alt=""
                  v-else-if="item.file_type === 'video'"
                />
                <img src="../../assets/img/图层 6.png" alt="" v-else />
                <span>{{ item.file_size }} </span>
              </div>
              <div class="types-right">
                <i class="el-icon-info" @click="toViewDetails(item)"></i>
                <i class="el-icon-edit" @click="editMaterials(item)"></i>
                <i class="el-icon-delete" @click="removeMaterial(item.id)"></i>
              </div>
            </div>
          </div>

                <!-- 分页区域 -->
          <div class="pagination">
            <span></span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryparms.page"
              :page-sizes="[12, 24, 36, 48]"
              :page-size="queryparms.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              algin:="center"
              ></el-pagination>
          </div>
        </el-card>
      </div>
    </div>

     <!-- 素材上传弹窗 -->
    <el-dialog title="批量上传素材" :visible.sync="addBatchDialog" width="30%" :close-on-click-modal=false @close="addBatchClose" v-loading="loading"
    element-loading-text="上传中,请稍后"
    element-loading-spinner="el-icon-loading">
      <template>
           <el-upload
              class="upload-demo"
              :file-list="fileListArr"  
              action="scfj"
              multiple                                
              :limit="10"                             
              :auto-upload="false"                     
              :on-change="onChangeFj"     
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <el-button
                style="margin-left: 10px;"
                size="small"
                type="success"
                @click="uploadFj"                      
                >立即上传</el-button
              >
            </el-upload>
      </template>
    </el-dialog>

               <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%" @close="dialogClose">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="名称：" label-width="100px">
            {{ detailsData.file_name }}
          </el-form-item>
          <el-form-item label="大小：" label-width="100px">
            {{ detailsData.file_size }}
          </el-form-item>
          <el-form-item label="类型：" label-width="100px">
            {{ detailsData.file_type }}
          </el-form-item>
          <el-form-item label="宽度：" label-width="100px">
            {{ detailsData.width }}
          </el-form-item>
          <el-form-item label="高度：" label-width="100px">
            {{ detailsData.height }}
          </el-form-item>
          <el-form-item label="创建时间：" label-width="100px">
            {{
              $moment(detailsData.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
           <el-form-item label="更新时间：" label-width="100px">
            {{
              $moment(detailsData.update_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
          <el-form-item :label="detailsType" label-width="100px">
           <template v-if="detailsData.file_type=='image'">
             <img :src="detailsData.avthumb" alt="" style="height:300px">
           </template>
          <template v-else-if="detailsData.file_type=='video'">
            <div  id="dplayerDetails" style="height:300px;outline: none;" ref="dplayerDetails" oncontextmenu="return(false)"></div>
             <!-- <video ref="videoDetails" :src="detailsData.playUrl" controls  style="height:300px;outline: none;" autoplay></video> -->
           </template>
          <template v-if="detailsData.file_type=='audio'">
            <audio ref="audioDetails" :src="detailsData.playUrl" muted autoplay="autoplay" controls="controls" style="outline: none;"></audio>
           </template>
          </el-form-item>

        </el-form>
      </el-dialog>

    <!-- 添加分类 -->
    <el-dialog title="添加分类" :visible.sync="addDialog" width="30%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="addTab">
            立即添加
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改分类 -->
    <el-dialog title="修改分类" :visible.sync="editDialog" width="30%">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input  v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="orderby">
          <el-input type="number" v-model="editForm.orderby"></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editTabs">
            立即修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改素材 -->
    <el-dialog title="修改素材" :visible.sync="editMaterialDialog" width="30%" @close="closeeditMaterialDialog">
      <el-form
        :model="editMaterialForm"
        :rules="rules"
        ref="editMaterialForm"
        label-width="100px"
      >
        <el-form-item label="素材名称" prop="file_name">
          <el-input v-model="editMaterialForm.file_name"></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="cate_id">
          <el-select
            v-model="editMaterialForm.cate_id"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in materialList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材排序" prop="orderby">
          <el-input type="number" v-model="editMaterialForm.orderby"></el-input>
        </el-form-item>
        <el-form-item label="选择音频" v-if="videoType=='video'">
          <el-button type="primary" @click="selectAudio(editMaterialForm)">选择音频</el-button>
        </el-form-item>
         <el-form-item v-if="editMaterialForm.relation.length!==0&&videoType=='video'" label="音频" label-width="100px">

          <div v-for="elm in editMaterialForm.relation_file" :key="elm.id">
            <audio class="taudioeditMaterial" :src="elm.key_url" muted autoplay="autoplay" controls="controls" style="outline: none;"></audio>
          </div>
          </el-form-item>
        
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editMaterial">
            立即修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 素材上传弹窗 -->
    <el-dialog title="上传素材" :visible.sync="addFileDialog" width="30%" @closed="closeBox" :close-on-click-modal=false :close-on-press-escape=false>
      <template  >
         <el-button type="primary" @click="toUpload">上传素材</el-button><br/>
            <span>{{addFileForm.file_name}}</span>
            <input type="file" hidden ref="file" @change="selectMaterial">
            <el-progress :percentage="progress" v-if="progress"></el-progress>

            <span slot="footer" class="dialog-footer">
             <el-button type="primary" @click="goUnpdateNow" :disabled="isGoUnpdate"
               >{{unpdateInner}}</el-button
             >
            </span>
      </template>
    </el-dialog>

     <!-- 点击素材弹框 -->
    <el-dialog :visible.sync="exhibition" width="850px" center @close="exhibitionClose">
      <img :src="exhibitionInfo.mediaUrl" alt="" class="exhibition" v-if="exhibitionInfo.file_type=='image'">
     
      <div  class="exhibition" v-else-if="exhibitionInfo.file_type=='video'" oncontextmenu="return(false)" ref="dplayer" id="dplayer"></div>
      <audio :src="exhibitionInfo.mediaUrl" ref="exhibitionAudio" v-else  muted autoplay="autoplay" controls="controls"></audio>
    </el-dialog>

    <!-- 回收站弹框 -->
    <el-dialog :visible.sync="recycleBinDialog" width="60%" center title="回收站" @close="closeRecycleBinDialog">
       <el-table :data="recycleBinList" stripe style="width: 100%" height='600' border>
        <el-table-column
          type="selection"
          width="55px"
          align="center"
        ></el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="60px">
        </el-table-column>
        <el-table-column label="素材" align="center">
           <template slot-scope="scope">
            <img :src="scope.row.avthumb" alt="" v-if="scope.row.file_type=='image'" style="width:200px;height:100px">
            <img src="../../assets/img/mp3.jpg" alt="" v-if="scope.row.file_type=='audio'" style="width:200px;height:100px">
            <img :src="scope.row.avthumb" alt="" v-if="scope.row.file_type=='video'" style="width:200px;height:100px">
            
          </template>
        </el-table-column>
        <el-table-column prop="file_name" label="素材名称" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-refresh-left"
                size="small"
                circle
                @click="revokeRecycleBin(scope.row.id)"
              ></el-button>
              <el-button type="danger" icon="el-icon-delete" circle size="small" @click="removeRecycleBin(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <!-- 选择素材 -->
    <el-dialog :visible.sync="sucaiPopup" width="60%"  title="选择素材">
        <div class="search">
              <el-form :inline="true" label-width="80px" ref="search">
                <el-form-item label-width="100px" label="素材分类">
                  <el-select clearable  v-model="audioparamsData.cate_id" filterable placeholder="请选择素材分类" @change="getMaterialClassification">
                    <el-option
                      v-for="(item,index) in materialList"
                      :key="index"
                      :label="item.title"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="100px" label="素材名">
                  <el-input
                    placeholder="请输入素材名"
                    v-model="audioparamsData.file_name"
                    @input="Input"
                  ></el-input>
                </el-form-item>
              </el-form>
        </div>
        <div style="padding:0 30px">
            <div class="material" @click="selectAudioId(item,index)" :style="item.isSelect?'background-color: #37a58c':''" v-for="(item, index) in materialListes" :key="index" >
                <img
                  src="../../assets/img/mp3.jpg"
                  alt=""
                  v-if="item.file_type === 'audio'"
                  
                />

                <img :src="item.avthumb" alt="" v-else />
                <p style="white-space: nowrap;text-overflow: ellipsis;">{{ item.file_name }}</p>
                <div class="types">
                  <div class="types-left">
                    <img
                      src="../../assets/img/material.png"
                      alt=""
                      v-if="item.file_type === 'image'"
                    />
                    <img
                      src="../../assets/img/图层 5.png"
                      alt=""
                      v-else-if="item.file_type === 'video'"
                    />
                    <img src="../../assets/img/图层 6.png" alt="" v-else />
                    <span>{{ item.file_size }} </span>
                  </div>
                </div>
          </div>
        </div>
         <!-- 分页 -->
        <div class="pagination">
          <span></span>
          <el-pagination
            @size-change="choiceSizeChange"
            @current-change="choiceCurrentChange"
            :current-page="audioparamsData.page"
            :page-sizes="[12, 24, 36, 48]"
            :page-size="audioparamsData.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="choiceTotal"
            algin:="center"
          ></el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="sucaiPopup = false">取 消</el-button>
          <el-button type="primary" @click="addMaterial">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterial,
  addCategory,
  removeCategory,
  editCategory,
  getFile,
  editFile,
  delFile,
  addFile,
  FileRecycleDel,
  FileRestore,
  FileCosToken,
} from "@/api/material";

var COS = require("cos-js-sdk-v5");
import { log } from "util";
import DPlayer from "dplayer";
export default {
  data() {
    return {
      sucaiPopup: false,
      materialListes: [],
      materialList: [],
      addDialog: false,
      editDialog: false,
      ruleForm: {
        name: "",
      },
      editForm: {
        title: "",
        orderby: "",
        id: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "分类名称不能为空",
            trigger: "blur",
          },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" },
        ],
        title: [
          {
            required: true,
            message: "修改的分类名称不能为空",
            trigger: "blur",
          },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        orderby: [
          {
            required: true,
            message: "素材排序不能为空",
            trigger: "blur",
          },
        ],
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur",
          },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        cate_id: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur",
          },
        ],
      },

      queryparms: {
        page: 1,
        limit: 12,
        file_name: null,
        file_type: null,
        cate_id: null,
        closed: null,
      },
      editMaterialDialog: false,
      editMaterialForm: {
        file_name: "",
        id: "",
        cate_id: "",
        orderby: "",
        relation: [],
      },
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      tab: [
        { name: "全部", file_type: null },
        { name: "图片", file_type: "image" },
        { name: "视频", file_type: "video" },
        { name: "音频", file_type: "audio" },
      ],
      FileList: [],
      total: 0,
      addFileForm: {
        cate_id: null,
        file_name: "",
        key: "",
        file_type: "",
      },
      isGoUnpdate: true,
      exhibition: false,
      exhibitionInfo: {
        file_type: "",
        mediaUrl: "",
      },
      unpdateAudioDialog: false,
      recycleBinDialog: false,
      recycleBinList: [],
      addFileDialog: false,
      progress: 0,
      unpdateInner: "立即上传",
      detailsData: {}, //详情数据
      detailsDialog: false, //详情弹框
      detailsType: "",

      addBatchDialog: false,
      fileList: [{ name: "food.jpeg" }],
      fileListArr: [],
      gwModleObj: {
        scfj: null,
      },
      saveSuccessId: null,
      addBatchFrom: {
        cate_id: null,
        file_name: "",
        file_type: "",
        key: "",
        image: null,
      },
      loading: false,
      search_type: "",
      VideoText: null, //视频组件
      DetailsVideoText: null,
      videoType: "",
      audioId: [],
      audioparamsData: {
        file_name: null,
        file_type: "audio",
        cate_id: null,
        closed: null,
        start_time: null,
        end_time: null,
        page: 1,
        limit: 12,
      },
      choiceTotal: 0,
      MaterialLists: [],
    };
  },
  methods: {
    Input() {
      this.getMaterialClassification();
    },
    // 下拉选择素材分类时触发
    getMaterialClassification() {
      this.audioparamsData.page = 1;

      getFile(this.audioparamsData).then((res) => {
        res.data.data.forEach((elm) => {
          elm.isSelect = false;
          this.editMaterialForm.relation.forEach((rez) => {
            if (rez == elm.id) {
              elm.isSelect = true;
            }
          });
        });
        this.choiceTotal = res.data.total;
        this.materialListes = res.data.data;
      });
    },
    // 选择素材分页条数
    choiceSizeChange(limit) {
      // console.log(limit)
      this.audioparamsData.limit = limit;

      getFile(this.audioparamsData).then((res) => {
        res.data.data.forEach((elm) => {
          elm.isSelect = false;
          this.editMaterialForm.relation.forEach((rez) => {
            if (rez == elm.id) {
              elm.isSelect = true;
            }
          });
        });
        this.choiceTotal = res.data.total;
        this.materialListes = res.data.data;
      });
    },
    choiceCurrentChange(page) {
      // console.log(page);
      this.audioparamsData.page = page;
      getFile(this.audioparamsData).then((res) => {
        res.data.data.forEach((elm) => {
          elm.isSelect = false;
          this.editMaterialForm.relation.forEach((rez) => {
            if (rez == elm.id) {
              elm.isSelect = true;
            }
          });
        });
        this.choiceTotal = res.data.total;
        this.materialListes = res.data.data;
      });
    },

    // 修改素材弹框关闭事件
    closeeditMaterialDialog() {
      let audioArr = document.querySelectorAll(".taudioeditMaterial");
      if (audioArr.length !== 0) {
        audioArr.forEach((item) => {
          item.pause();
        });
      }
    },

    // 选择素材确认
    addMaterial() {
      this.editMaterialForm.relation = [];
      this.editMaterialForm.relation_file = [];
      this.materialListes.forEach((item) => {
        if (item.isSelect == true) {
          this.editMaterialForm.relation.push(item.id);
          this.editMaterialForm.relation_file.push(item);
        }
      });
      this.sucaiPopup = false;
      this.$forceUpdate();
    },

    // 选择素材项
    selectAudioId(item, index) {
      this.materialListes[index].isSelect = !this.materialListes[index]
        .isSelect;
    },

    // 点击选择视频,打开选择素材弹框
    selectAudio(item) {
      this.sucaiPopup = true;
      getFile(this.audioparamsData).then((res) => {
        res.data.data.forEach((elm) => {
          elm.isSelect = false;
          item.relation.forEach((rez) => {
            if (rez == elm.id) {
              elm.isSelect = true;
            }
          });
        });
        this.choiceTotal = res.data.total;
        this.materialListes = res.data.data;
      });
    },
    handleSelect(value) {
      let data = {
        title: value,
      };
      getMaterial(data).then((res) => {
        this.materialList = res.data;
      });
    },
    //选择文件时候触发  需根据需求具体分析
    onChangeFj(file, fileList) {
      this.fileListArr = fileList;
    },

    //上传接口，进行上传
    uploadFj() {
      if (this.fileListArr.length <= 0) {
        this.$message.error("请选择文件后重试");
      }
      this.loading = true;
      let that = this;
      let count = 0;
      this.fileListArr.forEach((item) => {
        FileCosToken({ suffix: item.name }).then((res) => {
          var cos = new COS({
            getAuthorization: function (options, callback) {
              callback({
                TmpSecretId: res.data.credentials.tmpSecretId,
                TmpSecretKey: res.data.credentials.tmpSecretKey,
                XCosSecurityToken: res.data.credentials.sessionToken,
                StartTime: res.data.startTime,
                ExpiredTime: res.data.expiredTime,
                expiration: res.data.expiration,
                requestId: res.data.requestId,
              });
            },
          });
          cos.putObject(
            {
              Bucket: res.data.Bucket /* 存储桶 */ /* 必须 */,
              Region: res.data.Region /* 必须 */,
              Key: res.data.key /* 必须 */,
              Body: item.raw, // 上传文件对象
              onProgress: function (progressData) {},
            },
            function (err, data) {
              that.addBatchFrom.key = res.data.key;
              that.addBatchFrom.file_name = item.name;
              that.addBatchFrom.file_type = item.raw.type.split("/")[0];
              addFile(that.addBatchFrom).then((res) => {
                count++;
                if (res.code !== 1) {
                  this.$message.error(res.msg);
                  return;
                }
                if (count >= that.fileListArr.length) {
                  that.$message.success("上传成功");
                  that.loading = false;
                  that.addBatchDialog = false;
                }
              });
            }
          );
        });
      });
    },

    toBatchUpload() {
      if (this.addBatchFrom.cate_id == null) {
        this.$message.error("请选择分类");
        return;
      }
      this.addBatchDialog = true;
    },

    addBatchClose() {
      this.fileListArr = [];
      this.addBatchFrom.file_name = "";
      this.addBatchFrom.file_type = "";
      this.addBatchFrom.key = "";
      this.getFileList();
    },
    // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      if (row.file_type == "image") {
        this.detailsType = "缩略图：";
      } else if (row.file_type == "video") {
        this.detailsType = "视频：";
        setTimeout(() => {
          this.DetailsVideoText = new DPlayer({
            element: this.$refs.dplayerDetails, // 可选，player元素
            video: {
              // 必需，视频信息
              url: this.detailsData.playUrl, // 必填，视频网址
            },
            autoplay: true,
          });
        }, 500);
      } else if (row.file_type == "audio") {
        this.detailsType = "音频：";
      }
      this.detailsDialog = true;
    },

    // 详情弹窗关闭事件
    dialogClose() {
      if (this.detailsType == "视频：") {
        if (this.DetailsVideoText.video.paused !== true) {
          this.DetailsVideoText.pause();
        }
        // this.videoText=null;
      } else if (this.detailsType == "音频：") {
        this.$refs.audioDetails.pause();
      }
    },

    // 素材详情弹框
    exhibitionSay(elm) {
      this.exhibitionInfo.file_type = elm.file_type;
      this.exhibition = true;
      this.exhibitionInfo.mediaUrl = elm.playUrl;

      setTimeout(() => {
        if (elm.file_type == "video") {
          this.VideoText = new DPlayer({
            container: this.$refs.dplayer, // 可选，player元素
            video: {
              // 必需，视频信息
              url: elm.playUrl, // 必填，视频网址
            },
            autoplay: true,
          });
        }
      }, 500);
    },

    // 素材素略图弹窗关闭
    exhibitionClose() {
      if (this.exhibitionInfo.file_type == "video") {
        if (this.VideoText.video.paused !== true) {
          this.VideoText.pause();
        }
      } else if (this.exhibitionInfo.file_type == "audio") {
        this.$refs.exhibitionAudio.pause();
      }
    },

    //   获取侧边分类
    getMaterialList() {
      getMaterial().then((res) => {
        this.materialList = res.data;
      });
    },

    async removeTab(id) {
      const result = await this.$confirm("是否要删除该分类?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      removeCategory(id).then((res) => {
        this.getMaterialList();
        this.$message.success("删除分类成功");
      });
    },

    addTab() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          addCategory(this.ruleForm.name).then((res) => {
            this.$message.success("添加分类成功");
            this.getMaterialList();
            this.ruleForm.name = "";
            this.addDialog = false;
          });
        } else {
          return false;
        }
      });
    },

    // 点击修改分类图标
    editTab(item) {
      this.editForm.id = item.id;
      this.editForm.title = item.title;
      this.editForm.orderby = item.orderby;
      this.editDialog = true;
    },

    // 点击立即修改按钮,提交表单,修改分类名称
    editTabs() {
      editCategory(this.editForm).then((res) => {
        if (res.code !== 1) return this.$message.error("修改分类失败");
        this.$message.success("修改分类成功");
        this.getMaterialList();
        this.editDialog = false;
      });
    },
    // 分页切换
    handleSizeChange(size) {
      this.queryparms.limit = size;
      this.getFileList();
    },
    handleCurrentChange(page) {
      this.queryparms.page = page;
      this.getFileList();
    },

    // 删除素材
    async removeMaterial(id) {
      const result = await this.$confirm("是否删除该素材?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      delFile(id).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("已删除素材");
        this.getFileList();
      });
    },

    // 点击修改素材图标
    editMaterials(item) {
      this.videoType = item.file_type;
      this.editMaterialForm = item;
      if (this.editMaterialForm.relation == null) {
        this.editMaterialForm.relation = [];
      }
      this.editMaterialDialog = true;
    },

    // 立即修改素材
    editMaterial() {
      this.$refs.editMaterialForm.validate((valid) => {
        if (valid) {
          editFile(this.editMaterialForm).then((res) => {
            this.$message.success("修改成功");
            this.editMaterialDialog = false;
            this.getFileList();
          });
        } else {
          this.$message.error("修改失败");
          return false;
        }
      });
    },

    // 左侧tab栏切换
    toggleClass(i, item) {
      this.cur = i;
      this.queryparms.cate_id = item;
      this.addFileForm.cate_id = item;
      // this.addAvidoForm.cate_id=item
      this.addBatchFrom.cate_id = item;
      this.queryparms.page = 1;
      this.getFileList();
    },

    // 顶部tab栏切换
    toggletabs(i, elm) {
      this.tabs = i;
      this.queryparms.file_type = elm;
      this.getFileList();
    },

    // 获取素材列表
    getFileList() {
      getFile(this.queryparms).then((res) => {
        this.total = res.data.total;
        this.FileList = res.data.data;
      });
    },

    // 查询npm 
    toQuery() {
      this.getFileList();
    },

    // 点击上传素材按钮
    toUploadMaterial() {
      if (this.addFileForm.cate_id === null) {
        this.$message.error("请选择分类");
        return;
      }
      this.addFileDialog = true;
    },

    // 点击选择素材按钮
    toUpload() {
      this.$refs.file.click();
    },

    // 用户选择了素材触发
    selectMaterial() {
      let file = this.$refs.file.files[0];
      this.addFileForm.file_name = file.name;
      this.addFileForm.file_type = file.type.split("/")[0];
      this.isGoUnpdate = false;
    },

    // 点击立即上传素材
    goUnpdateNow() {
      let file = this.$refs.file.files[0];
      this.isGoUnpdate = true;
      this.unpdateInner = "上传中";
      FileCosToken({ suffix: file.name }).then((res) => {
        var that = this;
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
              if (progressData.percent == 1) {
                that.addFileForm.key = res.data.key;
              }
            },
          },
          function (err, data) {
            if (data) {
              addFile(that.addFileForm).then((res) => {
                if (res.code !== 1) {
                  that.$message.error(res.msg);
                }
                that.$message.success("上传成功");
                that.addFileDialog = false;
                that.isGoUnpdate = true;
                that.unpdateInner = "立即上传";
                that.getFileList();
              });
            }
          }
        );
      });
    },
    // 弹窗关闭事件
    closeBox() {
      this.addFileForm.file_name = "";
      this.progress = 0;
    },

    recycleBin() {
      this.recycleBinDialog = true;
      this.queryparms.closed = 1;
      getFile(this.queryparms).then((res) => {
        this.recycleBinList = res.data.data;
      });
    },
    //  回收站删除素材
    async removeRecycleBin(id) {
      const result = await this.$confirm("是否彻底删除该素材?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      FileRecycleDel(id).then((res) => {
        this.$message.success("已彻底删除素材");
        this.recycleBin();
      });
    },

    //  还原素材
    async revokeRecycleBin(id) {
      const result = await this.$confirm("是否还原该素材?", "还原提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消还原");
      FileRestore(id).then((res) => {
        this.$message.success("已还原素材");
        this.recycleBin();
      });
    },

    //  回收站素材回收弹窗关闭事件
    closeRecycleBinDialog() {
      this.queryparms.closed = null;
      this.getFileList();
    },
  },

  created() {
    this.getMaterialList();
    this.getFileList();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
li {
  list-style: none;
  cursor: pointer;
}
/* video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
} */

.box {
  display: flex;
}
.left {
  /* flex: 2; */
  /* position: relative; */
  width: 200px;
}
::v-deep .el-card__body {
  padding: 0;
}
.left ul {
  font-size: 14px;
  height: 700px;
  color: #333333;
  margin-bottom: 36px;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
.navs {
  margin-bottom: 20px;
  height: 120px;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  padding: 0 20px;
}
.navs .headers {
  /* display: flex; */
  border-bottom: 1px solid #eeeeee;
  line-height: 50px;
  width: 100%;
  height: 50px;
}
.navs .headers ul {
  width: 100%;
}
.navs .headers .selected {
  color: #38a28a;
  border-bottom: 1px solid #38a28a;
}
.navs .headers li {
  /* flex: 2; */
  height: 50px;
  float: left;
  margin-right: 60px;
}
.navs ::v-deep .el-form-item__label {
  color: #333333;
}
.add {
  width: 100%;
  /* position: absolute;
  bottom: 0;
  left: 0; */
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
  /* height: 30px; */
}

.search {
  display: flex;
  justify-content: space-between;
  line-height: 70px;
}

.contents {
  padding: 20px;
}
.material {
  position: relative;
  display: inline-block;
  width: 210px;
  height: 200px;
  /* background-color: skyblue; */
  margin: 0 20px 20px 0;
  text-align: center;
}
.material img {
  width: 100%;
  height: 118px;
}
.material p {
  line-height: 30px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
}
.material .types {
  display: flex;
  line-height: 50px;
  justify-content: space-between;
}
.material .types .types-left img {
  width: 18px;
  height: 18px;
}
.material .types span {
  color: #969696;
  font-size: 12px;
  margin-left: 10px;
}
.pagination {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.types-right {
  cursor: pointer;
}
.types .types-right i:nth-child(1) {
  margin-right: 10px;
  color: #85ce61;
}
.types .types-right i:nth-child(2) {
  margin-right: 10px;
}
.types .types-right i:nth-child(3) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.exhibition {
  width: 800px;
  height: 600px;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

::v-deep .el-dialog__body {
  padding: 30px 10px 100px 10px;
}
::v-deep .el-upload--text {
  border: none;
  width: 100px;
  height: 100%;
  position: unset;
  overflow: unset;
}
.search_type_box {
  display: flex;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}
.fs_01 {
  flex-shrink: 0;
}
.inline-input {
  width: 80%;
  margin-left: 5px;
}
</style>
